<template>
  <common-form
    ref="form"
    url="role"
    :edit="edit"
    :items="items"
    :beforeRequest="beforeRequest"
    @success="$emit('success')"></common-form>
</template>
<script>

  export default {
    data() {
      return {
        edit: false,
        treeData: [],
      }
    },
    computed: {
      items() {
        return [
          {
            type: "input",
            name: "角色名称",
            key: "roleName",
            required: true,
          },
          {
            type: "textarea",
            name: "角色描述",
            key: "remark",
            minRows: 2
          },
          {
            type: "tree",
            name: "权限选择",
            key: "menus",
            treeData: this.treeData
          },
        ]
      }
    },
    methods: {
      show(model = {}) {

        this.edit = model.roleId > 0

        this.$get('menu').then((r) => {
          this.treeData = r.data.rows.children
        })

        if (model.roleId > 0) {
          this.$get('role/menu/' + model.roleId).then((r) => {
            model.menus = r.data
            this.$refs.form.show(JSON.parse(JSON.stringify(model)))
          })
        } else {
          model.menus = []
          this.$refs.form.show(JSON.parse(JSON.stringify(model)))
        }

      },
      beforeRequest(model) {
        return {
          roleId: model.roleId,
          remark: model.remark,
          roleName: model.roleName,
          menuId: model.menus.join(',')
        }
      }
    }

  }
</script>
